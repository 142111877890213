<template>
  <van-list>
    <van-cell-group inset style="margin-top: 5.33333vw">
      <van-cell v-for="item in menuList" :key="item.title" :title="item.title" is-link
                @click="goTo(item.path)">
      </van-cell>
    </van-cell-group>
  </van-list>
</template>

<script>
import {Dialog} from "vant";

export default {
  name: "Setting",
  data() {
    return {
      menuList: [{title: '更新程序', path: '/checkUpdate'},
        {title: '退出登录', path: '/logout'}
      ]
    }
  },
  methods: {
    goTo: function (path) {
      if (_.isEmpty(path)) {
        return
      }
      switch (path) {
        case '/logout':
          this.logout();
          break;
        case '/checkUpdate':
          location=location.origin+"/dist2/index.html?version="+Math.random();
         /* this.checkUpdate();*/
          break;
        default:
          this.$router.push(path);
          break;
      }
    },
    logout: function () {
      this.$dialog.confirm({
        title: '退出登录',
        message: '是否确定退出登录',
      }).then(() => {
        // on confirm
        this.$sapi.clearUserInfo();
        this.$router.replace('/login')
      })
          .catch(() => {
            // on cancel
          });
    },
    checkUpdate() {
      try {
        var option = {
          portName: 'system.foundation.Dic.getDicDetail',
          data: {code: 'andorid_apk'},
          needLoading: false,
          successCallback: (data) => {
            var entity = data.entities[0];
            Capacitor.Plugins.AppUpdate.checkUpdate({
              version: entity.value,
              downloadUrl: entity.dicAttach[0].url,
              desc: entity.des,
            });
          }
        }
        this.$sapi.callPort(option);
      } catch (error) {
        alert(error.message);
      }
    }
  }
}
</script>

<style scoped>

</style>